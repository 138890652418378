
import { Proposal } from '../models/proposal.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

const API_URL = environment.apiUrl;

@Injectable()
export class ProposalService {
  constructor(private http: HttpClient) {}

  headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});;

  private inProcessSubject$ = new BehaviorSubject<boolean>(false);
  inProgress$ = this.inProcessSubject$.asObservable();

  public create(proposal: Proposal): Observable<Proposal> {
    this.inProcessSubject$.next(true);

    return this.http.put<Proposal>(API_URL + 'proposal/', JSON.stringify(proposal), {headers: this.headers})
      .pipe(
        tap(_ => {
          this.inProcessSubject$.next(false);
          // return this.notificationsService.showNotification('success', 'Success', `Order \"${id}\" successfully saved`, 3000);
        }),
        catchError(this.handleError<Proposal>(null))

      );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      const message = error.message ? error.message : 'Something went wrong. Please try again later or contact RealPage';
      // this.notificationsService.showNotification('danger', 'Error', message, 3000);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
