import { Component, OnInit } from '@angular/core';
import {Review} from '../../../models/review.model';
import {ProposalService} from '../../../service/proposal.service';
import {ReviewService} from '../../../service/review.service';
import {Proposal} from '../../../models/proposal.model';

@Component({
  selector: 'app-reviews-slider',
  templateUrl: './reviews-slider.component.html',
  styleUrls: ['./reviews-slider.component.css']
})
export class ReviewsSliderComponent implements OnInit {

  constructor(
    private reviewService: ReviewService
  ) { }
  reviews: Review[];

  ngOnInit() {
    this.reviewService.list()
      /*.pipe(takeUntil(this.unsubscribe))*/
      .subscribe(
        reviews => {
          this.reviews = reviews;
        }
      );
  }

}
