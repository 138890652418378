
import { Proposal } from '../models/proposal.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {Review} from '../models/review.model';

const API_URL = environment.apiUrl;

@Injectable()
export class ReviewService {
  constructor(private http: HttpClient) {}

  headers = new HttpHeaders();

  private inProcessSubject$ = new BehaviorSubject<boolean>(false);
  inProgress$ = this.inProcessSubject$.asObservable();

  public list(): Observable<Review[]> {
    return this.http.get<Review[]>(API_URL + 'review/', {headers: this.headers});
  }


  public create(review: Review): Observable<Review> {
    this.inProcessSubject$.next(true);

    return this.http.put<Review>(API_URL + 'review/', JSON.stringify(review), {headers: this.headers})
      .pipe(
        tap(_ => {
          this.inProcessSubject$.next(false);
          // return this.notificationsService.showNotification('success', 'Success', `Order \"${id}\" successfully saved`, 3000);
        }),
        catchError(this.handleError<Review>(null))

      );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      const message = error.message ? error.message : 'Something went wrong. Please try again later or contact RealPage';
      // this.notificationsService.showNotification('danger', 'Error', message, 3000);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
