import { Proposal } from '../../../models/proposal.model';
import { NgForm } from '@angular/forms';
import {ProposalService} from '../../../service/proposal.service';
import {Component} from '@angular/core';

@Component({
  selector: 'app-proposal-form',
  templateUrl: './proposal-form.component.html',
  styleUrls: ['./proposal-form.component.css']
})
export class ProposalFormComponent  {

  constructor(
    private proposalService: ProposalService
  ) {}

  model = new Proposal();

  submitted = false;

  onSubmit() {
    this.submitted = true;
    this.proposalService.create(this.model).subscribe(
      (proposal: Proposal) => {
        console.log(proposal);
      });
  }

  // TODO: Remove this when we're done
  get diagnostic() { return JSON.stringify(this.model); }
}
