import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './pages/main/main.component';
import {MainRoutingModule} from './main-routing.module';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import {ProposalFormComponent} from './pages/main/proposal-form/proposal-form.component';
import {FormsModule} from '@angular/forms';
import { ReviewsSliderComponent } from './pages/main/reviews-slider/reviews-slider.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MainRoutingModule
  ],
  declarations: [MainComponent, ProposalFormComponent, ReviewsSliderComponent, PageNotFoundComponent, ReviewsSliderComponent]
})
export class MainModule { }
